import '../plugins/mobileNavPlugin';

// open-close init
export default function initMobileNav() {
	jQuery('body').mobileNav({
		menuActiveClass: 'nav-active',
		menuOpener: '.nav-opener'
	});

	$("#tours-spinner-btn").on("click", () => {
		$("body").data('MobileNav').hide();
    })
}
