import '../plugins/tabsPlugin';

// content tabs init
export default function initTabs() {
	jQuery('.location-list').tabset({
		tabLinks: 'a[href*="data-tab"]',
		addToParent: true,
		checkHash: true,
		defaultTab: true
	});
}