import '../plugins/slickCarouselPlugin';

// slick init
export default function initSlickCarousel() {
	const heroBoxClasses = ['bg-info', 'bg-success', 'bg-orange', 'bg-warning'];
	const movingBox = jQuery('#hero-color-box');

	jQuery('.decor.decor-left').each(function () {
		$(this).css({
			height: jQuery('.intro-slider').height()
		});
	});

	const desktopSlider = jQuery('.intro-slider');

	desktopSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		desktopSlider.slick('slickPause');

		const holder = jQuery('.intro');
		const animeClass = 'anime';
		holder.removeClass(animeClass);

		if ($(window).width() < 768) {
			return;
		}

		var movingBoxWidth;
		if ($(window).width() < 1024) {
			movingBoxWidth = $(window).width();
		} else {
			movingBoxWidth = $('.hero-content').width() + 90;
		}

		movingBox.animate({
			width: movingBoxWidth
		}, 750);

		const container = jQuery('.slick-current .container');
		const leftOuterWidth = (container.outerWidth(true) - container.width()) / 2;
		movingBox.animate({
			left: movingBoxWidth + leftOuterWidth,
			width: 0
		}, 750, function () {
			movingBox.removeClass(function (index, className) {
				return (className.match(/\bbg-\S+/g) || []).join(' ');
			});
			movingBox.addClass(heroBoxClasses[nextSlide]);
			movingBox.removeAttr('style');
		});
	});

	jQuery('.intro-slider').slick({
		slidesToScroll: 1,
		rows: 0,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000,
		pauseOnFocus: false,
		pauseOnHover: false,
		speed: 1,
		prevArrow: '<button class="slick-prev" aria-label="Previous"><i class="icon-arrow-left-bold"></i></button>',
		nextArrow: '<button class="slick-next" aria-label="Next"><i class="icon-arrow-right-bold"></i></button>',
		appendArrows: '.intro .controls',
		asNavFor: '.intro-img-slider'
	});

	jQuery('.intro-slider').on('touchstart', function () {
		$(this).slick('slickPlay');
	});

	jQuery('.intro-img-slider').slick({
		slidesToScroll: 1,
		rows: 0,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 4000,
		asNavFor: '.intro-slider',
		focusOnSelect: true,
		centerMode: true,
		centerPadding: '0px'
	});

	jQuery('.stories-slider').slick({
		slidesToScroll: 2,
		slidesToShow: 3,
		rows: 0,
		infinite: false,
		prevArrow: '<button class="slick-prev" aria-label="Previous"><i class="icon-arrow-left-bold"></i></button>',
		nextArrow: '<button class="slick-next" aria-label="Next"><i class="icon-arrow-right-bold"></i></button>',
		variableWidth: true,
		appendArrows: '.stories-slider-wrapp .controls',
		responsive: [{
			breakpoint: 1200,
			settings: {
				slidesToScroll: 2,
				slidesToShow: 2
			}
		}, {
			breakpoint: 1024,
			settings: {
				slidesToScroll: 1,
				slidesToShow: 2
			}
		}, {
			breakpoint: 768,
			settings: {
				slidesToScroll: 1,
				slidesToShow: 1
			}
		}]
	});

	jQuery('.services-slider').each(function() {
		var slider = jQuery(this);

		ResponsiveHelper.addRange({
			'..767': {
				on: function() {
					slider.slick({
						slidesToScroll: 1,
						slidesToShow: 1,
						rows: 0,
						arrows: false,
						dots: true,
						autoplay: true,
						autoplaySpeed: 2000,
						dotsClass: 'slick-dots',
					});
				}
			},
			'768..': {
				on: function() {
					if (slider.hasClass('slick-initialized')) {
						setTimeout(function() {
							slider.slick('unslick');
						}, 100);
					}
				}
			}
		});
	});

	jQuery('.cta-img-slider').each(function() {
		var slider = jQuery(this);

		ResponsiveHelper.addRange({
			'..767': {
				on: function() {
					slider.slick({
						slidesToScroll: 1,
						slidesToShow: 1,
						rows: 0,
						arrows: false,
						centerMode: true,
						centerPadding: '0px'
					});
				}
			},
			'768..': {
				on: function() {
					if (slider.hasClass('slick-initialized')) {
						setTimeout(function() {
							slider.slick('unslick');
						}, 100);
					}
				}
			}
		});
	});
}

/*
 * Responsive Layout helper
 */
window.ResponsiveHelper = (function($){
	// init variables
	var handlers = [],
		prevWinWidth,
		win = $(window),
		nativeMatchMedia = false;

	// detect match media support
	if(window.matchMedia) {
		if(window.Window && window.matchMedia === Window.prototype.matchMedia) {
			nativeMatchMedia = true;
		} else if(window.matchMedia.toString().indexOf('native') > -1) {
			nativeMatchMedia = true;
		}
	}

	// prepare resize handler
	function resizeHandler() {
		var winWidth = win.width();
		if(winWidth !== prevWinWidth) {
			prevWinWidth = winWidth;

			// loop through range groups
			$.each(handlers, function(index, rangeObject){
				// disable current active area if needed
				$.each(rangeObject.data, function(property, item) {
					if(item.currentActive && !matchRange(item.range[0], item.range[1])) {
						item.currentActive = false;
						if(typeof item.disableCallback === 'function') {
							item.disableCallback();
						}
					}
				});

				// enable areas that match current width
				$.each(rangeObject.data, function(property, item) {
					if(!item.currentActive && matchRange(item.range[0], item.range[1])) {
						// make callback
						item.currentActive = true;
						if(typeof item.enableCallback === 'function') {
							item.enableCallback();
						}
					}
				});
			});
		}
	}
	win.bind('load resize orientationchange', resizeHandler);

	// test range
	function matchRange(r1, r2) {
		var mediaQueryString = '';
		if(r1 > 0) {
			mediaQueryString += '(min-width: ' + r1 + 'px)';
		}
		if(r2 < Infinity) {
			mediaQueryString += (mediaQueryString ? ' and ' : '') + '(max-width: ' + r2 + 'px)';
		}
		return matchQuery(mediaQueryString, r1, r2);
	}

	// media query function
	function matchQuery(query, r1, r2) {
		if(window.matchMedia && nativeMatchMedia) {
			return matchMedia(query).matches;
		} else if(window.styleMedia) {
			return styleMedia.matchMedium(query);
		} else if(window.media) {
			return media.matchMedium(query);
		} else {
			return prevWinWidth >= r1 && prevWinWidth <= r2;
		}
	}

	// range parser
	function parseRange(rangeStr) {
		var rangeData = rangeStr.split('..');
		var x1 = parseInt(rangeData[0], 10) || -Infinity;
		var x2 = parseInt(rangeData[1], 10) || Infinity;
		return [x1, x2].sort(function(a, b){
			return a - b;
		});
	}

	// export public functions
	return {
		addRange: function(ranges) {
			// parse data and add items to collection
			var result = {data:{}};
			$.each(ranges, function(property, data){
				result.data[property] = {
					range: parseRange(property),
					enableCallback: data.on,
					disableCallback: data.off
				};
			});
			handlers.push(result);

			// call resizeHandler to recalculate all events
			prevWinWidth = null;
			resizeHandler();
		}
	};
}(jQuery));