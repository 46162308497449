// import 'Plugins/customFormsPlugin';

// initialize custom form elements
export default function initScrollMenu() {
	const win = jQuery(window);
	const parent = jQuery('.filter-wrapp, .location-list-wrapp');

	jQuery('.scrollable').each(function() {
		const holder = jQuery(this);
		const activeClass = 'end-position';

		checkWidth();

		function checkWidth() {
			const listWidth = holder.find('.location-list').innerWidth();
			const listParenWidth = holder.innerWidth();

			if (listParenWidth <= listWidth) {
				parent.removeClass(activeClass);
			} else {
				parent.addClass(activeClass);
			}
		}

		holder.on('scroll resize', function() {
			if (this.scrollLeft >= this.scrollWidth - this.clientWidth - 16) {
				parent.addClass(activeClass);
			} else {
				parent.removeClass(activeClass);
			}
		});

		win.on('resize orientationchange', checkWidth);
	});
}