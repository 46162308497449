﻿export default function copyLinkInit() {
    $(".copy-link-item").on("click", function (event) {
        event.stopPropagation();
        event.preventDefault();

        const link = $(this);

        const href = link.data("href");
        navigator.clipboard.writeText(href).then(function () {
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    });
}