﻿export default function initCostComparison() {

    const openedClass = "opened";
    const activeClass = "active";
    const dataId = "data-id";
    const dataColor = "data-color";
    const hidden = "hidden";

    const sections = $(".cost-comparison-section");
    for (const section of sections) {
        const $section = $(section);
        const button = $section.find(".select-button");
        const dropdown = $section.find(".cities-dropdown");
        const tabs = $section.find(".features-tab");
        const lis = dropdown.find("li");

        button.on("click", function () {
            toggleElements(button, dropdown);
        });

        dropdown.on("click", "li", function () {
            const li = $(this);
            const id = li.attr(dataId);
            const color = li.attr(dataColor);
            const text = li.text();
            const currentColor = button.attr(dataColor);

            const activeTab = tabs.filter(`.${activeClass}`);
            const newTab = tabs.filter(`#${id}`);
            activeTab.animate({ opacity: 0 }, 300, () => {
                activeTab.removeClass(activeClass);
                newTab.css("opacity", 0)
                      .addClass(activeClass)
                      .animate({ opacity: 1 }, 300);
            });

            toggleElements(button, dropdown);            
            button.removeClass(currentColor)
                .addClass(color)
                .attr(dataColor, color);

            button.find("span").text(text);

            lis.removeClass(hidden);
            li.addClass(hidden);
        })
    }

    function toggleElements(button, dropdown) {
        button.toggleClass(openedClass);
        dropdown.fadeToggle(300);
    }
}