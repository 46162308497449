﻿import * as bootstrap from 'bootstrap';

const localStorageKey = "IsAcknowledgmentOfCountryWasShown";
const modalElementId = "acknowledgment-modal";

export default function initAcknowledgmentOfCountry() {
    const item = localStorage.getItem(localStorageKey);
    if (item) {
        return;
    }

    const modalElement = document.getElementById(modalElementId);
    if (!modalElement) {
        return;
    }

    const modal = new bootstrap.Modal(modalElement, {
        backdrop: true
    });

    modal.show();

    localStorage.setItem(localStorageKey, true);
}