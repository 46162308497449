﻿export default function initYoutubeButtons() {

    let $videoSrc;
    $('.btn-video-youtube').click(function (e) {
        const src = $(this).data("src");
        $videoSrc = getEmbedYouTubeLink(src);
    });

    $('#youtube-modal').on('shown.bs.modal', function (e) {
        $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
    }).on('hide.bs.modal', function (e) {
        $("#video").attr('src', $videoSrc);
    })
}

function getEmbedYouTubeLink(link) {
    const embedRegex = /embed/ig;
    if (embedRegex.test(link)) {
        return link;
    }

    const baseEmbedString = "https://www.youtube.com/embed/";
    const url = new URL(link);

    const youtubeRegex = /youtube.com/ig;
    if (youtubeRegex.test(link)) {
        const code = url.searchParams.get("v");

        return `${baseEmbedString}${code}`;
    }

    const shortRegex = /youtu.be/ig;
    if (shortRegex.test(link)) {
        const code = url.pathname.substring(1);

        return `${baseEmbedString}${code}`;
    }

    return "";
}