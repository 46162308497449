﻿export default function initSubmitHandler() {
    window.submitSubscriptionForm = replaceForm;
}

function replaceForm(form) {

    const inputErrorClass = "input-validation-error";
    const inputSuccessClass = "subscription-success";

    const messageSuccessClass = "success-message";
    const messageErrorClass = "error-message";    

    const holder = document.querySelector(".newslatter-holder");
    const input = holder.querySelector("#email");
    const message = holder.querySelector(".subscription-message"); 
   

    $.ajax({
        method: "POST",
        url: form.action,
        data: $(form).serialize(),
        success: function (data) {
            resetFields();

            const inputClass = data.isValid ? inputSuccessClass : inputErrorClass;
            const messageClass = data.isValid ? messageSuccessClass : messageErrorClass;

            input.classList.add(inputClass);
            message.innerHTML = data.message;
            message.classList.add(messageClass);

        }, error: function () {
            resetFields();

            input.classList.add(inputErrorClass);
            message.innerHTML = "Please enter a valid email address";
            message.classList.add(messageErrorClass);            
        }
    });

    function resetFields() {
        input.classList.remove(inputErrorClass);
        input.classList.remove(inputSuccessClass);

        message.classList.remove(messageErrorClass);
        message.classList.remove(messageSuccessClass);
    }
}

