import 'bootstrap';
import ready, { HTML } from './utils';
import initMobileNav from './components/mobileNav';
import initSlickCarousel from './components/slickCarousel';
import initFancybox from './components/fancybox';
import initInViewport from './components/inViewport';
import initAddClass from './components/addClass';
import initChangeBg from './components/changeBg';
import initScrollMenu from './components/scrollMenu';
import initHeadroom from './components/headroom';
import initPackery from './components/packery';
import initTabs from './components/tabs';
import freezeGif from './components/freezeGif';
import youtubeModalTrigger from './components/youtubeModalTrigger';
import shareLink from './components/shareLink';
import initSubmitHandler from './components/subscriptionForm';
import contactFormSubmitInterceptor from './components/contactFormSubmit';
import initCostComparison from './components/initCostComparison';
import initAcknowledgmentOfCountry from './components/acknowledgmentOfCountry';

ready(() => {
    HTML.classList.add('is-loaded');
    initMobileNav();
    initSlickCarousel();
    initFancybox();
    initAddClass();
    initChangeBg();
    initScrollMenu();
    initHeadroom();
    initTabs();
    freezeGif();
    youtubeModalTrigger();
    shareLink();
    initSubmitHandler();
    contactFormSubmitInterceptor();
    initCostComparison();
    initAcknowledgmentOfCountry();
});

jQuery(window).on('load', function (e) {
    initPackery();
    initInViewport();
});
