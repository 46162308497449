﻿export default function freezeGif() {

    const hiddenImgClass = "hidden-img";
    const imagesContainerClass = ".gif-freeze-container";
    const cardsContainerClass = ".cards-container";
    

    const containers = [...document.querySelectorAll(cardsContainerClass)];

    const sections = containers.map(container => ({ section: container.parentElement, container }));
    for (const obj of sections) {
        $(obj.section).on("in-viewport", (event, val) => {
            if (val) {
                proccessContainer(obj.container);
            }
        });
    }

    function proccessContainer(container) {
        const images =[...container
            .querySelectorAll(imagesContainerClass)]
            .map(container => container.querySelectorAll("img"));        
          
        if (images.length === 0 || images[0].length === 1) {
            return;
        }

        const readyToAnimate = images
            .reduce((acc, cur) => {
                acc.push({ animation: cur[1], staticImg: cur[0] });
                return acc;
            }, []);
        
       setTimeout(() => startAnimation(readyToAnimate), 1000);
    }

    function startAnimation(toAnimate) {
        for (let i = 0; i < toAnimate.length; i++) {
            const timeout = setTimeout(() => {
                animate(toAnimate[i], timeout)
            }, i * 1000);
        }

        function animate(pair, timeout) {
            show(pair.animation);
            hide(pair.staticImg);

            const innerTimeout = setTimeout(() => {
                show(pair.staticImg);
                hide(pair.animation);

                clearTimeout(innerTimeout);
                clearTimeout(timeout);
            }, 4000);
        }
    }



    function show(element) {
        element.classList.remove(hiddenImgClass);
    }

    function hide(element) {
        element.classList.add(hiddenImgClass);
    }
};

