﻿export default function contactFormSubmitInterceptor() {
    $(".contact-form").on("submit", (event) => {
        const target = event.target;
        if (target) {
            const submit = target.querySelector(".btn-submit");
            if (submit) {
                submit.disabled = true;
            }
        }
    });
}