export default function initChangeBg() {
	const win = jQuery(window);
	const page = jQuery('body');
	const sections = jQuery('#main .intro, #main .viewport-section');
	let winHeight, scrollTop = 0;

	function resizeHandler() {
		winHeight = getWindowHeight();

		sections.each((i, el) => {
			const section = jQuery(el);

			section.data({
				height: section.outerHeight(),
				offset: section.offset().top
			});
		});

		scrollHandler();
	}

	function scrollHandler() {
		scrollTop = win.scrollTop() + winHeight / 3;

		sections.each((i, el) => {
			const section = jQuery(el);

			if (section.data('offset') <= scrollTop && section.data('offset') + section.data('height') > scrollTop) {
				if (section.data('color')) {
					page.css({
						backgroundColor: section.data('color')
					});
				} else {
					page.css({
						backgroundColor: '#fff'
					});
				}
			}
		});
	}

	function getWindowHeight() {
		return typeof window.innerHeight === 'number' ? window.innerHeight : document.documentElement.clientHeight;
	}

	resizeHandler();

	win.on('resize orientationchange', resizeHandler).on('scroll', scrollHandler);
}